import dynamic from "next/dynamic"

import { serverSideTranslations } from "next-i18next/serverSideTranslations"

const DynamicCatalogueScreen = dynamic(() => import("../src/app/components/domains/catalogue/catalogue-screen"), {
  ssr: true,
})

const Home = () => {
  return <DynamicCatalogueScreen />
}

export default Home

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  }
}
